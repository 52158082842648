import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/mdx.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageHeading = makeShortcode("PageHeading");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageHeading title={props.pageContext.frontmatter.title} image="meeting" crumbs={["TechSlice", props.pageContext.frontmatter.title]} mdxType="PageHeading"></PageHeading>
    <div className="container" style={{
      marginBottom: "4rem"
    }}>
      <h1><u>{`Terms of Use`}</u></h1>
      <h2>{`Acceptance of Terms of Use`}</h2>
      <p>{`TechSlice, LLC (“COMPANY”) require that all visitors (“you”) to the COMPANY website (techslice.com) (“Site”) abide by the following Terms of Use (“TOU”).  By accessing and using the Site you indicate your agreement to these TOU.  IF YOU DO NOT AGREE TO THESE TOU, PLEASE DO NOT ACCESS OR USE THE SITE.  COMPANY reserves the right to modify these TOU at any time and in any manner, without prior notice.`}</p>
      <h2>{`Rights`}</h2>
      <p>{`COMPANY owns this Site and COMPANY, or its content providers, own and/or retain the rights to all content, Web pages, source code, materials, data, information, text, screens, services, design, layout, screen interfaces, “look and feel”, logo or graphic images appearing herein, and the operation of this Site (collectively, “Materials”), which are protected by various intellectual property laws, including, but not limited to, copyrights, patents, trade secrets, trademarks, and service marks.  Nothing in these TOU transfers any rights in the Materials to you or any third party and you acknowledge and agree that you do no not acquire any ownership rights by downloading or viewing any Materials.`}</p>
      <h2>{`Use Limitation`}</h2>
      <p><em parentName="p">{`No Unlawful or Prohibited Use`}</em></p>
      <p>{`As a condition of your use of the Site, you acknowledge and agree that you will not use the Site for any purpose that is unlawful or otherwise prohibited by these TOU.  You will not use the Site in any manner that could damage, disable, overburden, or impair the Site or any related service provider server, or the network(s) connected to the Site or any related service provider server, or interfere with any other party’s use and enjoyment of the Site.`}</p>
      <h2>{`Linking`}</h2>
      <p>{`A link to a linked website (“Linked Site”) on this Site does not mean that COMPANY endorses or accepts any responsibility for the content, functioning or use of such Linked Site, and you enter any such website at your own risk.  You agree that COMPANY has no control over or liability for information on Linked Sites.  You should be aware that Linked Sites may contain rules and regulations, privacy provisions, confidentiality provisions, and other provisions that are different from the provisions provided on this Site.  COMPANY is not responsible for such provisions, and expressly disclaims any and all liability related to such provisions.  COMPANY prohibits unauthorized hypertext links to this Site, or the framing of this Site.`}</p>
      <h2>{`Disclaimer of Warranties`}</h2>
      <p>{`THE SITE AND ALL OTHER INFORMATION OR SERVICES AVAILABLE ON THIS SITE, INCLUDING TEXT, IMAGES, AND LINKS, AND THE INFORMATION ON ANY LINKED SITE, WHETHER AFFILIATED OR UNAFFILIATED WITH COMPANY, WHICH YOU MAY VISIT THROUGH THIS SITE, ARE PROVIDED “AS IS,” “WHERE IS,” AND “AS AVAILABLE,” BY COMPANY, AS A CONVENIENCE TO ALL USERS WITHOUT REPRESENTATION OR WARRANTY OF ANY KIND INCLUDING, BUT NOT LIMITED TO, ANY EXPRESS OR IMPLIED WARRANTIES (I) OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE; (II) OF INFORMATIONAL CONTENT OR ACCURACY; (III) OF NON-INFRINGEMENT; (IV) OF QUIET ENJOYMENT; (V) OF TITLE; (VI) THAT THIS SITE OR ANY SERVICES WILL OPERATE ERROR FREE, OR IN AN UNINTERRUPTED FASHION; (VII) THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR (VIII) THAT THIS SITE IS COMPATIBLE WITH ANY PARTICULAR HARDWARE OR SOFTWARE PLATFORM.  EFFORTS BY COMPANY TO MODIFY THE SITE SHALL NOT BE DEEMED A WAIVER OF THESE LIMITATIONS.  THE SITE COULD INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS.  CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN.  COMPANY AND/OR ITS SERVICE PROVIDERS MAY MAKE CHANGES IN THE SITE AT ANY TIME AND FROM TIME TO TIME.`}</p>
      <h2>{`Limitation of Liability`}</h2>
      <p>{`COMPANY SHALL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOSS OF PROFITS, LOSS OF USE, LOSS OF DATA, INTERRUPTION OF BUSINESS, OR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OF ANY KIND HOWSOEVER CAUSED, WHETHER ARISING UNDER THESE TOU, FROM USE OF THE SITE OR OTHERWISE, EVEN IF COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR WAS NEGLIGENT.  THIS LIMITATION ON LIABILITY INCLUDES, BUT IS NOT LIMITED TO, THE TRANSMISSION OF ANY VIRUSES THAT MAY INFECT A USER’S EQUIPMENT, FAILURE OF MECHANICAL OR ELECTRONIC EQUIPMENT OR COMMUNICATION LINES, TELEPHONE OR OTHER INTERCONNECT PROBLEMS, UNAUTHORIZED ACCESS, THEFT, OPERATOR ERRORS, STRIKES OR OTHER LABOR PROBLEMS OR ANY FORCE MAJEURE EVENT.  IN JURISDICTIONS THAT PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, COMPANY’S LIABILITY IS LIMITED TO THE GREATEST EXTENT PERMITTED BY THE LAW.`}</p>
      <h2>{`Termination`}</h2>
      <p>{`COMPANY reserves the right to discontinue providing and/or to terminate your access to the Site or any portion thereof at any time.`}</p>
      <h2>{`Governing Law and International Use`}</h2>
      <p>{`This Site only is directed to persons in the continental United States, Hawaii and Alaska, however, it may be accessed from many different places around the world.  By accessing this Site, you agree that the statutes and laws of the State of Maryland, without regard to any conflicts of laws principles that would apply the law of another jurisdiction, and including the Maryland Uniform Electronic Transactions Act, will apply to all matters relating to the use of this Site.  You further agree that exclusive jurisdiction for all disputes, claims or controversies relating to use of this Site shall be only in a federal or state court with competent jurisdiction in the State of Maryland.  You irrevocably consent to the exercise of personal jurisdiction by such courts in any such dispute, claim or controversy and irrevocably waive any objection on the ground of venue or the convenience of the forum.  Any judgment entered in any such dispute, claim or controversy may be enforced in other jurisdictions in any manner provided by law.  Those who choose to access this Site from other locations do so on their own initiative and are responsible for compliance with local laws.`}</p>
      <h2>{`Feedback`}</h2>
      <p>{`To the extent permitted by applicable law, any comments or materials (“Feedback”) sent to COMPANY including questions, suggestions, ideas, comments, or the like relating to this Site shall be deemed to be non-confidential and shall become the property of COMPANY upon receipt.  COMPANY shall have no obligation of any kind with respect to such Feedback and shall be free to transmit, reproduce, use, exhibit, disclose, display, transform, copyright, create derivative works, and distribute the Feedback to others without limitation.  Further, COMPANY shall be free to use any ideas, concepts, know-how and techniques contained in such Feedback for any purpose whatsoever, including but not limited to, developing, manufacturing and marketing products or services incorporating such information.`}</p>
      <h2>{`Miscellaneous`}</h2>
      <p>{`These TOU constitute the entire agreement between you and COMPANY and supersede all prior or contemporaneous communications, promises and proposals, whether oral, written or electronic, between you and COMPANY with respect to this Site.  If any part of these TOU is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid enforceable provision that most closely matches the intent of the original provision, and the remainder of these TOU shall continue in effect.  A printed version of these TOU and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to these TOU to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.  All rights not expressly granted herein are reserved.`}</p>
      <p><em parentName="p">{`For More Information`}</em></p>
      <p>{`If you have any comments, concerns or questions regarding these TOU, please contact us at (410) 891-4050 or visit our contact page.`}</p>
      <p>{`Last updated on July 5, 2021.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      